import { MoreHoriz } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { validationSchemaNumeroPieces } from 'src/constants/prejudice/validation';
import * as yup from 'yup';
import {
  CalculsFormListeProjection,
  CalculsGlobal,
} from '../../../../constants/calculs';
import {
  COMMENTAIRE_PGPF_RELIQUAT,
  ListeProjectionFonctionnelPermanent,
  ListeProjectionFonctionnelPermanentRow,
  NumeroPieceValuesRow,
  PrejudiceFormListeProjection,
} from '../../../../types/prejudice.type';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { ComputedTextFieldForm } from '../../../forms/ComputedTextFieldForm';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { NormalTable } from '../../../styled';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
interface Props {
  control: Control<PrejudiceFormListeProjection>;
  dateConsolidation: Date | undefined;
  dateNaissance: string | undefined;
  allNumerosPieces: NumeroPieceValuesRow[];
}

export const deficitFonctionnelPermanentPGPFReliquatRow = (
  IPPGPFReliquat: number,
): ListeProjectionFonctionnelPermanentRow => ({
  commentaires: COMMENTAIRE_PGPF_RELIQUAT,
  pourcentage: 0,
  valeurPoint: 0,
  montant: IPPGPFReliquat || 0,
  numerosPieces: { rows: [] },
});

export const validationSchemaDeficitFonctionnelPermanent =
  (): yup.ObjectSchema<ListeProjectionFonctionnelPermanent> =>
    yup.object({
      rows: yup
        .array()
        .defined()
        .of(
          yup.object({
            numerosPieces: validationSchemaNumeroPieces,
            commentaires: yup.string().optional().defined(),
            pourcentage: yup.number().required().min(0).max(100),
            valeurPoint: yup.number().required(),
            montant: yup.number().required(),
          }),
        ),
    });
const initialValuesRow: ListeProjectionFonctionnelPermanentRow = {
  commentaires: '',
  montant: 0,
  pourcentage: 0,
  valeurPoint: 0,
  numerosPieces: { rows: [] },
};

export const getInitialValuesDeficitFonctionnelPermanent = (
  values: ListeProjectionFonctionnelPermanent | undefined,
): ListeProjectionFonctionnelPermanent => ({
  rows:
    values?.rows?.map((row) => ({
      ...initialValuesRow,
      ...row,
    })) || [],
});

export const DeficitFonctionnelPermanent: React.FC<Props> = ({
  control,
  dateConsolidation,
  dateNaissance,
  allNumerosPieces,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuOpen =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  };

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'prejudiceValues.rows',
  });

  const handleDelete = (index: number) => {
    remove(index);
    handleMenuClose();
  };

  const duplicateRow = (index: number) => {
    const rowToDuplicate = fields[index];
    insert(index, {
      ...rowToDuplicate,
    } as ListeProjectionFonctionnelPermanentRow);
    handleMenuClose();
  };

  const ageConsolidation = useMemo(
    () =>
      dateNaissance && dateConsolidation
        ? CalculsGlobal.getAgeOrZero(dateNaissance, dateConsolidation)
        : undefined,
    [dateNaissance, dateConsolidation],
  );

  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell>{t('numeroPiece.form.columnHeader')}</TableCell>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.commentaires.columnHeader',
                )}
              </TableCell>
              {ageConsolidation && (
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.ageConsolidation.columnHeader',
                  )}
                </TableCell>
              )}
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.pourcentage.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.valeurPoint.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.montant.columnHeader',
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>
                  <FormNumeroPieceDialog
                    control={control}
                    name={`prejudiceValues.rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.commentaires`}
                    sx={{ width: 350 }}
                  />
                </TableCell>
                {ageConsolidation && (
                  <TableCell align="center">
                    {t('forms.fields.age.value', {
                      count: ageConsolidation.age,
                      context: ageConsolidation.isExactlyZero
                        ? 'exactly_zero'
                        : undefined,
                    })}
                  </TableCell>
                )}
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.pourcentage`}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 0, min: 0, max: 100 },
                        suffix: '%',
                      } as IMaskNumberProps,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.valeurPoint`}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, signed: true },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ComputedTextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.montant`}
                    watchFields={[
                      `prejudiceValues.rows.${index}.pourcentage`,
                      `prejudiceValues.rows.${index}.valeurPoint`,
                    ]}
                    compute={(values) => {
                      const [pourcentage, valeurPoint] = values.map(Number);

                      return CalculsFormListeProjection.getMontantDeficitFonctionnelPermanent(
                        {
                          pourcentage: pourcentage || 0,
                          valeurPoint: valeurPoint || 0,
                        },
                      );
                    }}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, signed: true },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={handleMenuOpen(index)}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openMenuIndex === index}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleDelete(index)}>
                      {t('forms.table.deleteRow', { index: index + 1 })}
                    </MenuItem>
                    <MenuItem onClick={() => duplicateRow(index)}>
                      {t('forms.table.duplicateRow', { index: index + 1 })}
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <Button
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          append(initialValuesRow);
        }}
      >
        {t('forms.table.addRow')}
      </Button>
      <ComputedPropsForm
        control={control}
        watchFields={['prejudiceValues.rows']}
        compute={([rows]) => ({
          props: {
            montantTotal:
              CalculsFormListeProjection.getDeficitFonctionnelPermanentIndemnite(
                {
                  rows: rows as ListeProjectionFonctionnelPermanentRow[],
                },
              ),
          },
        })}
        render={(values) => {
          const { montantTotal } = values as {
            montantTotal: ReturnType<
              typeof CalculsFormListeProjection.getDeficitFonctionnelPermanentIndemnite
            >;
          };

          return (
            <>
              <ComputedReadFieldForm
                name="montantTotal"
                control={control}
                watchFields={[]}
                customValues={[montantTotal]}
                compute={(_, customValues) => customValues?.[0] || 0}
              />
            </>
          );
        }}
      />
    </>
  );
};
