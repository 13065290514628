import React, { useMemo } from 'react';
import { Button, Grid, InputAdornment, Stack } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import {
  PrejudiceFormCalendrierValeur,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { TableValeurs } from '../prejudiceFormComponents/TableValeurs';
import { TotalDeficit } from '../prejudiceFormComponents/TotalDeficit';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { useTranslation } from 'react-i18next';
import { Victime } from 'src/types/victime.type';
import {
  getDefaultMinAndMaxDate,
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { prejudicesData } from 'src/constants/prejudices';
import { dateString } from 'src/helpers/yup';
import { Procedure } from 'src/types/procedure.type';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import i18next from 'i18next';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';
import { sortBy } from 'lodash';

export const validationSchemaCalendrierValeur = ({
  victime,
  noRevalorisation,
  prejudiceType,
  procedure,
}: {
  victime: Victime;
  noRevalorisation: boolean | undefined;
  prejudiceType: PrejudiceType;
  procedure: Procedure;
}): yup.ObjectSchema<PrejudiceFormCalendrierValeur> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  return validationSchemaPrejudiceBase.shape({
    rows: yup
      .array()
      .defined()
      .of(
        yup.object({
          numerosPieces: validationSchemaNumeroPieces,
          date: dateString()
            .nullable()
            .required()
            .minDate(undefined, minDate.message, minDate.date)
            .maxDate(undefined, maxDate.message, maxDate.date),
          montant: yup.number().required().min(0),
          intitule: yup.string().required(),
          montantsDejaRevalorises: yup
            .boolean()
            .when([], () =>
              noRevalorisation
                ? yup.boolean().optional()
                : yup.boolean().required(),
            ),
        }),
      ),
    total: yup.number().required().min(0),
  });
};

export const calendrierValeurDefaultValues = ({
  values,
  prejudiceType,
}: {
  values: PrejudiceFormCalendrierValeur | undefined;
  prejudiceType: PrejudiceType;
}): PrejudiceFormCalendrierValeur => ({
  ...prejudiceBaseDefaultValues({
    prejudiceType,
    values,
  }),
  notes: values?.notes || '',
  rows: sortBy(values?.rows || [], (row) => row.date),
  total: values?.total || 0,
});

type Props = PrejudiceFormProps<PrejudiceFormCalendrierValeur>;

export const FormCalendrierValeur: React.FC<Props> = ({
  procedure,
  victime,
  values,
  monetaryErosions,
  displayTotalPartResponsabilite,
  partResponsabilite,
  allNumerosPieces,
  ...props
}) => {
  const noRevalorisation =
    prejudicesData[props.prejudiceType || 'DEPENSE_SANTE_FUTURES']
      .noRevalorisation;
  const useFormReturn = useForm<PrejudiceFormCalendrierValeur>({
    defaultValues: calendrierValeurDefaultValues({
      values,
      prejudiceType: props.prejudiceType,
    }),
    resolver: yupResolver(
      validationSchemaCalendrierValeur({
        victime,
        noRevalorisation,
        prejudiceType: props.prejudiceType,
        procedure,
      }),
    ),
  });
  const { control } = useMemo(() => useFormReturn, [useFormReturn]);
  const { t } = useTranslation();

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormCalendrierValeur>
      {...props}
      victime={victime}
      procedure={procedure}
      monetaryErosions={monetaryErosions}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack direction="column" spacing={4} flex={1}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={10}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          <TableValeurs
            control={control}
            dateLiquidation={procedure.dateLiquidation}
            monetaryErosions={monetaryErosions}
            noRevalorisation={noRevalorisation}
            procedure={procedure}
            victime={victime}
            prejudiceType={props.prejudiceType}
            allNumerosPieces={allNumerosPieces}
          />
          {displayTotalPartResponsabilite && partResponsabilite && (
            <ComputedPropsForm
              control={control}
              watchFields={['total']}
              compute={([total]) => ({
                props: {
                  totalPartResponsabilite: Number(total) * partResponsabilite,
                  total: Number(total),
                },
              })}
              render={({ totalPartResponsabilite, total }) => (
                <Stack alignSelf="end" maxWidth="400px">
                  <TotalDeficit
                    totalPartResponsabilite={totalPartResponsabilite}
                    totalMontantTotal={total}
                    displayPartResponsabilite={displayTotalPartResponsabilite}
                    partResponsabilite={partResponsabilite}
                  />
                </Stack>
              )}
            />
          )}
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Stack>
      )}
    />
  );
};
