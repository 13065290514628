import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  Prejudice,
  PrejudiceType,
  PGPFReliquatAndPGPFReliquatActivationStatus,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormPerteRevenusProche,
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  PrejudiceFormValeur,
  PrejudiceFormListeProjection,
  PrejudiceFormCalendrierValeur,
  PrejudiceFormMateriel,
  PrejudiceFormScolaire,
  PrejudiceFormCalendrierAssistance,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormValeurVictimesIndirectes,
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormCalendrierDepenseCapitalisation,
} from 'src/types/prejudice.type';
import { Paragraph, Table, TableOfContents } from 'docx';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { Bareme } from 'src/types/bareme.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { PrejudiceTotalValue } from '../../prejudices/total';
import { prejudiceTitlePrint } from '../prejudicesPrints/prejudiceTitlePrint';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import i18next from 'i18next';
import { pgpaRevenusWrittenPrint } from './prejudices/perteDeGain/revenus/pgpaRevenus.written.print';
import { prpVictimeDecedeeWrittenPrint } from './prejudices/pertesRevenusDesProches/prpVictimeDecedee.written.print';
import { valeurWrittenPrint } from './prejudices/valeur.written.print';
import { listeProjectionWrittenPrint } from './prejudices/listeProjection/listeProjection.written.print';
import { calendrierValeursWrittenPrint } from './prejudices/calendrierValeurs.written.print';
import { materielWrittenPrint } from './prejudices/materiel.written.print';
import { scolaireWrittenPrint } from './prejudices/scolaire.written.print';
import { atptWrittenPrint } from './prejudices/atpt.written.print';
import { pgpfRevenusWrittenPrint } from './prejudices/perteDeGain/revenus/pgpfRevenus.written.print';
import { pgpaDeclarationFiscaleWrittenPrint } from './prejudices/perteDeGain/declarationFiscale/pgpaDeclarationFiscale.written.print';
import { pgpaSaisieDirecteWrittenPrint } from './prejudices/perteDeGain/saisieDirecte/pgpaSaisieDirecte.written.print';
import { pgpfSaisieDirecteWrittenPrint } from './prejudices/perteDeGain/saisieDirecte/pgpfSaisieDirecte.written.print';
import { pgpfDeclarationFiscaleWrittenPrint } from './prejudices/perteDeGain/declarationFiscale/pgpfDeclarationFiscale.written.print';
import { dsaWrittenPrint } from './prejudices/dsa.written.print';
import { isPrejudiceVictimeIndirecte } from 'src/helpers/prejudices/prejudice';
import { valeurVictimesIndirectesWrittenPrint } from './prejudices/valeurVictimesIndirectes.written.print';
import { atpfWrittenPrint } from './prejudices/atpf.written.print';
import { dftpWrittenPrint } from './prejudices/dftp.written.print';
import { dfttWrittenPrint } from './prejudices/dftt.written.print';
import { cdcWrittenPrint } from './prejudices/cdc.written.print';
import { prpVictimeBlesseWrittenPrint } from './prejudices/pertesRevenusDesProches/prpVictimeBlessee.written.print';

const makePrejudiceContentPrint = ({
  monetaryErosions,
  prejudice,
  procedure,
  victime,
  baremes,
  PGPFReliquat,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  prejudice: Pick<Prejudice, 'formType' | 'formData' | 'type'>;
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  victimesIndirectes: VictimeIndirecte[];
}): (Table | TableOfContents | Paragraph)[] | undefined => {
  switch (prejudice.formType) {
    case 'PERTE_GAINS_PROFESSIONNELS_ACTUEL': {
      let formData = prejudice.formData as
        | OldPrejudiceFormPerteGainProfessionnelsActuel
        | NewPrejudiceFormPerteGainProfessionnelsActuel
        | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
      switch (formData.formType) {
        case 'DECLARATION_FISCALES': {
          formData = formData as OldPrejudiceFormPerteGainProfessionnelsActuel;
          return pgpaDeclarationFiscaleWrittenPrint({
            victime,
            procedure,
            formData,
            monetaryErosions,
          });
        }
        case 'REVENUS': {
          formData = formData as NewPrejudiceFormPerteGainProfessionnelsActuel;
          return pgpaRevenusWrittenPrint({
            victime,
            procedure,
            formData,
            monetaryErosions,
          });
        }
        case 'SAISIE_DIRECTE': {
          formData =
            formData as PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
          return pgpaSaisieDirecteWrittenPrint({
            victime,
            procedure,
            formData,
            monetaryErosions,
          });
        }
        default:
          return [];
      }
    }
    case 'PERTE_GAINS_PROFESSIONNELS_FUTURS': {
      let formData = prejudice.formData as
        | OldPrejudiceFormPerteGainProfessionnelsFuturs
        | NewPrejudiceFormPerteGainProfessionnelsFuturs
        | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
      switch (formData.formType) {
        case 'REVENUS': {
          formData = formData as NewPrejudiceFormPerteGainProfessionnelsFuturs;
          return pgpfRevenusWrittenPrint({
            victime,
            procedure,
            formData,
            monetaryErosions,
            baremes,
          });
        }
        case 'SAISIE_DIRECTE': {
          formData =
            formData as PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
          return pgpfSaisieDirecteWrittenPrint({
            victime,
            procedure,
            formData,
            monetaryErosions,
            baremes,
          });
        }
        case 'DECLARATION_FISCALES': {
          formData = formData as OldPrejudiceFormPerteGainProfessionnelsFuturs;
          return pgpfDeclarationFiscaleWrittenPrint({
            victime,
            procedure,
            formData,
            monetaryErosions,
            baremes,
          });
        }
        default:
          return [];
      }
    }
    case 'PERTES_REVENUS_PROCHE': {
      const formData = prejudice.formData as
        | PrejudiceFormPerteRevenusProche
        | PrejudiceFormPerteRevenusProcheVictimeNonDecedee;
      if (victime.dateDeces && 'victime' in formData) {
        return prpVictimeDecedeeWrittenPrint({
          formData,
          victime,
          procedure,
          victimesIndirectes,
          baremes,
          monetaryErosions,
        });
      } else {
        return prpVictimeBlesseWrittenPrint({
          formData:
            formData as PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
          procedure,
          victimesIndirectes,
          monetaryErosions,
        });
      }
    }
    case 'VALEUR': {
      const formData = prejudice.formData as PrejudiceFormValeur;
      return valeurWrittenPrint({
        formData,
        procedure,
        victime,
        prejudice,
      });
    }
    case 'LISTE_PROJECTION': {
      return listeProjectionWrittenPrint({
        prejudice,
        procedure,
        victime,
        formData: prejudice.formData as PrejudiceFormListeProjection,
        PGPFReliquat,
      });
    }
    case 'MATERIEL': {
      return materielWrittenPrint({
        formData: prejudice.formData as PrejudiceFormMateriel,
        prejudice,
        procedure,
        victime,
        monetaryErosions,
      });
    }
    case 'CALENDRIER_VALEURS':
      return calendrierValeursWrittenPrint({
        formData: prejudice.formData as PrejudiceFormCalendrierValeur,
        prejudice,
        victime,
      });
    case 'SCOLAIRE':
      return scolaireWrittenPrint({
        formData: prejudice.formData as PrejudiceFormScolaire,
        procedure,
        victime,
      });
    case 'CALENDRIER_ASSISTANCE':
      return atptWrittenPrint({
        formData: prejudice.formData as PrejudiceFormCalendrierAssistance,
        victime,
        procedure,
        monetaryErosions,
      });
    case 'CALENDRIER_DEPENSE':
      return dsaWrittenPrint({
        formData: prejudice.formData as PrejudiceFormCalendrierDepense,
        victime,
        procedure,
        monetaryErosions,
        isVictimeIndirecte: isPrejudiceVictimeIndirecte(prejudice.type),
        prejudiceType: prejudice.type,
        victimesIndirectes,
      });
    case 'VALEUR_VICTIMES_INDIRECTES':
      return valeurVictimesIndirectesWrittenPrint({
        formData: prejudice.formData as PrejudiceFormValeurVictimesIndirectes,
        victime,
        prejudiceType: prejudice.type,
        victimesIndirectes,
      });
    case 'CALENDRIER_ASSISTANCE_CAPITALISATION':
      return atpfWrittenPrint({
        formData:
          prejudice.formData as PrejudiceFormCalendrierAssistanceCapitalisation,
        victime,
        procedure,
        monetaryErosions,
        baremes,
      });
    case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL':
      return dftpWrittenPrint({
        formData:
          prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
        victime,
        procedure,
      });
    case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL':
      return dfttWrittenPrint({
        formData:
          prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
        victime,
        procedure,
      });
    case 'CALENDRIER_DEPENSE_CAPITALISATION':
      return cdcWrittenPrint({
        formData:
          prejudice.formData as PrejudiceFormCalendrierDepenseCapitalisation,
        victime,
        procedure,
        monetaryErosions,
        prejudiceType: prejudice.type,
        baremes,
      });
    default:
      return undefined;
  }
};

export const makePrejudiceWrittenPrint = ({
  baremes,
  monetaryErosions,
  PGPFReliquat,
  prejudicesTotalValues,
  prejudice,
  procedure,
  victime,
  victimesIndirectes,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudice: Pick<Prejudice, 'formType' | 'formData' | 'type'>;
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  prejudicesTotalValues: Partial<Record<PrejudiceType, PrejudiceTotalValue[]>>;
}): (Paragraph | Table | TableOfContents)[] | undefined => {
  try {
    const total = prejudicesTotalValues[prejudice.type];
    const prejudiceContent = makePrejudiceContentPrint({
      baremes,
      monetaryErosions,
      PGPFReliquat,
      prejudice,
      procedure,
      victimesIndirectes,
      victime,
    });
    return [
      prejudiceTitlePrint(prejudice, total ?? []),
      ...(prejudiceContent ? [...prejudiceContent] : []),
    ];
  } catch (e) {
    console.error(
      i18next.t('print.prejudice.error', {
        prejudiceName: i18next.t(
          `prejudice.prejudicesTypes.${prejudice.type}.title`,
        ),
        prejudice: JSON.stringify(prejudice, null, 2),
        error: e?.toString(),
      }),
    );
    return undefined;
  }
};
