import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  Control,
  Controller,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { NormalTable, SpanNoWrap } from '../../../styled';

import { MoreHoriz } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import { getDefaultMinAndMaxDate } from 'src/constants/prejudice/validation';
import {
  getMontantRevalorise,
  getQuotientRevalorisationTooltip,
} from 'src/helpers/prejudices/revalorisation';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { fCurrency } from '../../../../helpers/formatNumber';
import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  NumeroPieceValuesRow,
  PerteDeGainsProfessionnelsRow,
  PrejudiceFormPerteGainProfessionelsFormType,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  PrejudiceType,
  unites,
} from '../../../../types/prejudice.type';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { DatePickerForm } from '../../../forms/DatePickerForm';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';

interface Props {
  control: Control<
    | NewPrejudiceFormPerteGainProfessionnelsActuel
    | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
  >;
  isError?: boolean;
  dateAccident?: Date | undefined;
  dateConsolidation?: Date | undefined;
  dateLiquidation: Date | undefined;
  isPGPF?: boolean;
  monetaryErosions: MonetaryErosion[];
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
  allNumerosPieces: NumeroPieceValuesRow[];
  formType: Exclude<
    PrejudiceFormPerteGainProfessionelsFormType,
    'DECLARATION_FISCALES'
  >;
  getValues:
    | UseFormGetValues<NewPrejudiceFormPerteGainProfessionnelsActuel>
    | UseFormGetValues<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>
    | UseFormGetValues<NewPrejudiceFormPerteGainProfessionnelsFuturs>
    | UseFormGetValues<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte>;
}

const TablePerteDeGainsProfessionnels: React.FC<Props> = ({
  control,
  isError = false,
  dateLiquidation,
  dateConsolidation,
  isPGPF = false,
  monetaryErosions,
  procedure,
  victime,
  prejudiceType,
  allNumerosPieces,
  formType,
  getValues,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuOpen =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  };

  const handleDelete = (index: number) => {
    remove(index);
    handleMenuClose();
  };

  const duplicateRow = (index: number) => {
    const rowToDuplicate = fields[index];
    insert(index, {
      ...rowToDuplicate,
    } as PerteDeGainsProfessionnelsRow);
    handleMenuClose();
  };

  const { fields, append, insert, remove } = useFieldArray({
    name: 'perteDeGainsProfessionnels.rows',
    control,
  });

  const getInitialValuesRow = (
    revenuDeReferenceAnnuel: number,
    revalorisationCoefficientsType: NewPrejudiceFormPerteGainProfessionnelsActuel['revalorisationCoefficientsType'],
  ): PerteDeGainsProfessionnelsRow => {
    const dateDebut = isPGPF ? dateConsolidation?.toISOString() ?? null : null;
    const dateFin = isPGPF ? dateLiquidation?.toISOString() ?? null : null;
    const duree =
      isPGPF && dateConsolidation && dateLiquidation
        ? CalculsFormNewPerteGainsProfessionnelsActuels.getDuree({
            dateDebut: dateConsolidation.toISOString(),
            dateFin: dateLiquidation.toISOString(),
          })
        : 0;
    const revenuDeReferencePeriode =
      CalculsFormNewPerteGainsProfessionnelsActuels.getRevenuDeReferencePeriode(
        {
          revenuDeReferenceAnnuel,
          duree,
        },
      );
    const revenuPercuParPeriode =
      CalculsFormNewPerteGainsProfessionnelsActuels.getRevenuPercuParPeriode({
        revenuPercuNet: 0,
        duree,
        unite: '',
      });
    const perteDeGainsProfessionnels =
      CalculsFormNewPerteGainsProfessionnelsActuels.getPerteDeGainsProfessionnels(
        {
          revenuDeReferencePeriode,
          revenuPercuParPeriode,
          montantsDejaRevalorises: false,
          dateFin,
          monetaryErosions,
          dateLiquidation,
          revalorisationCoefficientsType,
        },
      );
    return {
      unite: '',
      montantsDejaRevalorises: false,
      dateDebut,
      dateFin,
      duree,
      revenuPercuNet: 0,
      revenuDeReferenceAnnuel,
      revenuDeReferencePeriode,
      revenuPercuParPeriode,
      perteDeGainsProfessionnels,
      numerosPieces: { rows: [] },
      commentaires: '',
    };
  };
  const {
    minDate: { date: minDate },
    maxDate: { date: maxDate },
  } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });

  const onClickAppend = () => {
    let revenu: number | undefined;
    const values = getValues() as
      | NewPrejudiceFormPerteGainProfessionnelsActuel
      | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
    if (formType === 'REVENUS') {
      revenu = (values as NewPrejudiceFormPerteGainProfessionnelsActuel)
        .revenuActiviteAnnuelDeReference.total;
    } else {
      revenu = (
        values as PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
      ).revenuEspere.total;
    }

    values.perteDeGainsProfessionnels.rows.sort((a, b) => {
      if (a.dateDebut && b.dateDebut) {
        return (
          new Date(a.dateDebut).getTime() - new Date(b.dateDebut).getTime()
        );
      }
      return 0;
    });

    const newRow: PerteDeGainsProfessionnelsRow = getInitialValuesRow(
      revenu,
      values.revalorisationCoefficientsType,
    );

    append({
      ...newRow,
      dateDebut:
        values.perteDeGainsProfessionnels.rows[
          values.perteDeGainsProfessionnels.rows.length - 1
        ]?.dateFin || null,
    });
  };
  return (
    <>
      <Stack alignItems="start" width="100%">
        <CalculsBox padding={2}>
          <FormControl>
            <FormLabel
              sx={{
                textAlign: 'center',
              }}
            >
              {t('prejudice.revalorisation.coefficientsType.choice.label')}
            </FormLabel>
            <RadioFormField
              control={control}
              name="revalorisationCoefficientsType"
              options={[
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.annuel',
                  ),
                  value: 'annuel',
                },
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.smic',
                  ),
                  value: 'smic',
                },
              ]}
              row={true}
            />
          </FormControl>
        </CalculsBox>
      </Stack>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.commentaires.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {formType === 'REVENUS'
                  ? isPGPF
                    ? t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.perteDeGainsProfessionnels.rows.dateDebut.periodeColumnHeader',
                      )
                    : t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.dateDebut.periodeColumnHeader',
                      )
                  : t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.perteDeGainsProfessionnels.rows.dateDebut.periodeColumnHeader',
                    )}
                <Stack direction="row" justifyContent="space-around">
                  <div>
                    {t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.dateDebut.columnHeader',
                    )}
                  </div>
                  <div>
                    {t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.dateFin.columnHeader',
                    )}
                  </div>
                </Stack>
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.duree.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {formType === 'REVENUS'
                  ? t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.revenuDeReferenceAnnuel.columnHeader',
                    )
                  : t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.perteDeGainsProfessionnels.rows.revenuDeReferenceAnnuel.columnHeader',
                    )}
              </TableCell>
              <TableCell align="center">
                {formType === 'REVENUS'
                  ? t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.revenuDeReferencePeriode.columnHeader',
                    )
                  : t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.perteDeGainsProfessionnels.rows.revenuDeReferencePeriode.columnHeader',
                    )}
              </TableCell>
              <TableCell align="center">
                {formType === 'REVENUS'
                  ? t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.revenuPercuNet.columnHeader',
                    )
                  : t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.perteDeGainsProfessionnels.rows.revenuPercuNet.columnHeader',
                    )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.revenuPercuParPeriode.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.revalorisation.columnHeaders.montantDejaRevalorise',
                )}
              </TableCell>
              <TableCell align="center" sx={{ minWidth: '150px' }}>
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.revenuPercuParPeriode.revalorise.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.perteDeGainsProfessionnels.columnHeader',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell align="center">
                  <FormNumeroPieceDialog
                    control={control}
                    name={`perteDeGainsProfessionnels.rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextFieldForm
                    control={control}
                    name={`perteDeGainsProfessionnels.rows.${index}.commentaires`}
                  />
                </TableCell>
                <TableCell align="center">
                  <Stack direction="row" spacing={2}>
                    <DatePickerForm
                      control={control}
                      name={`perteDeGainsProfessionnels.rows.${index}.dateDebut`}
                      minDate={minDate}
                      maxDate={maxDate}
                      label={t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.dateDebut.label',
                      )}
                      TextFieldProps={{
                        InputLabelProps: { shrink: true },
                        sx: {
                          minWidth: 155,
                          width: 155,
                        },
                      }}
                    />
                    <DatePickerForm
                      control={control}
                      name={`perteDeGainsProfessionnels.rows.${index}.dateFin`}
                      label={t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.dateFin.label',
                      )}
                      minDate={minDate}
                      maxDate={maxDate}
                      TextFieldProps={{
                        InputLabelProps: { shrink: true },
                        sx: {
                          minWidth: 155,
                          width: 155,
                        },
                      }}
                    />
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <ComputedTextFieldForm
                    name={`perteDeGainsProfessionnels.rows.${index}.duree`}
                    watchFields={[
                      `perteDeGainsProfessionnels.rows.${index}.dateDebut`,
                      `perteDeGainsProfessionnels.rows.${index}.dateFin`,
                    ]}
                    control={control}
                    compute={([dateDebut, dateFin]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getDuree({
                        dateDebut,
                        dateFin,
                      })
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 0 },
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                <TableCell align="center">
                  {formType === 'REVENUS' ? (
                    <ComputedTextFieldForm
                      name={`perteDeGainsProfessionnels.rows.${index}.revenuDeReferenceAnnuel`}
                      watchFields={['revenuActiviteAnnuelDeReference.total']}
                      control={control}
                      compute={([total]) => total || 0}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: { scale: 2, signed: true },
                          suffix: '€',
                        } as IMaskNumberProps,
                      }}
                      editedFieldsName="editedFields"
                    />
                  ) : (
                    <ComputedReadFieldForm
                      name={`perteDeGainsProfessionnels.rows.${index}.revenuDeReferenceAnnuel`}
                      watchFields={['revenuEspere.total']}
                      control={control}
                      compute={([total]) => {
                        return total || 0;
                      }}
                      render={(value) => <>{fCurrency(value)}</>}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <ComputedTextFieldForm
                    name={`perteDeGainsProfessionnels.rows.${index}.revenuDeReferencePeriode`}
                    watchFields={[
                      `perteDeGainsProfessionnels.rows.${index}.revenuDeReferenceAnnuel`,
                      `perteDeGainsProfessionnels.rows.${index}.duree`,
                    ]}
                    control={control}
                    compute={([revenuDeReferenceAnnuel, duree]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getRevenuDeReferencePeriode(
                        {
                          revenuDeReferenceAnnuel,
                          duree,
                        },
                      )
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, signed: true },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                <TableCell align="center">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <TextFieldForm
                      control={control}
                      name={`perteDeGainsProfessionnels.rows.${index}.revenuPercuNet`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          suffix: '€',
                          numberMask: { scale: 2, signed: true },
                        } as IMaskNumberProps,
                      }}
                    />
                    <SelectFieldForm
                      control={control}
                      name={`perteDeGainsProfessionnels.rows.${index}.unite`}
                      style={{
                        minWidth: 130,
                        width: 130,
                      }}
                      options={unites.map((unite) => ({
                        label: t(`prejudice.fields.unite.optionsPar.${unite}`),
                        value: unite,
                      }))}
                    />
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <ComputedTextFieldForm
                    name={`perteDeGainsProfessionnels.rows.${index}.revenuPercuParPeriode`}
                    watchFields={[
                      `perteDeGainsProfessionnels.rows.${index}.duree`,
                      `perteDeGainsProfessionnels.rows.${index}.revenuPercuNet`,
                      `perteDeGainsProfessionnels.rows.${index}.unite`,
                      `perteDeGainsProfessionnels.rows.${index}.montantsDejaRevalorises`,
                      `perteDeGainsProfessionnels.rows.${index}.dateFin`,
                      'revalorisationCoefficientsType',
                    ]}
                    control={control}
                    compute={([duree, revenuPercuNet, unite]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getRevenuPercuParPeriode(
                        {
                          duree,
                          revenuPercuNet,
                          unite,
                        },
                      )
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, signed: true },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                <TableCell align="center">
                  <Controller
                    control={control}
                    name={`perteDeGainsProfessionnels.rows.${index}.montantsDejaRevalorises`}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                </TableCell>
                <TableCell align="center">
                  {/* TODO : put this into a separate reusable component to display a montant revalorise */}
                  <ComputedPropsForm
                    control={control}
                    watchFields={[
                      `perteDeGainsProfessionnels.rows.${index}.revenuPercuParPeriode`,
                      `perteDeGainsProfessionnels.rows.${index}.montantsDejaRevalorises`,
                      `perteDeGainsProfessionnels.rows.${index}.dateFin`,
                      'revalorisationCoefficientsType',
                    ]}
                    compute={([
                      revenuPercuParPeriode,
                      montantsDejaRevalorises,
                      dateFin,
                      revalorisationCoefficientsType,
                    ]) => {
                      if (
                        !revenuPercuParPeriode ||
                        !dateFin ||
                        montantsDejaRevalorises
                      ) {
                        return {
                          props: {
                            revenuPercuParPeriode,
                            quotientRevalorisationTooltip: '',
                            revenuPercuParPeriodeRevalorise: null,
                            displayMontantRevalorise: false,
                          },
                        };
                      }
                      return {
                        props: {
                          revenuPercuParPeriode,
                          quotientRevalorisationTooltip:
                            dateFin && !montantsDejaRevalorises
                              ? getQuotientRevalorisationTooltip({
                                  anneeOrDateMontant: dateFin,
                                  monetaryErosions,
                                  anneeOrDateLiquidation: dateLiquidation,
                                  coefficientsType:
                                    revalorisationCoefficientsType,
                                })
                              : '',
                          revenuPercuParPeriodeRevalorise:
                            dateFin && !montantsDejaRevalorises
                              ? getMontantRevalorise({
                                  anneeOrDateMontant: dateFin,
                                  montant: revenuPercuParPeriode,
                                  monetaryErosions,
                                  anneeOrDateLiquidation: dateLiquidation,
                                  coefficientsType:
                                    revalorisationCoefficientsType,
                                })
                              : null,
                          displayMontantRevalorise: true,
                        },
                      };
                    }}
                    render={({
                      revenuPercuParPeriode,
                      quotientRevalorisationTooltip,
                      revenuPercuParPeriodeRevalorise,
                      displayMontantRevalorise,
                    }) =>
                      !displayMontantRevalorise ? (
                        <SpanNoWrap>
                          {fCurrency(revenuPercuParPeriode || 0)}
                        </SpanNoWrap>
                      ) : (
                        <Tooltip
                          title={quotientRevalorisationTooltip || ''}
                          placement="top"
                        >
                          <SpanNoWrap>
                            {fCurrency(revenuPercuParPeriodeRevalorise || 0)}
                          </SpanNoWrap>
                        </Tooltip>
                      )
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  <ComputedReadFieldForm
                    name={`perteDeGainsProfessionnels.rows.${index}.perteDeGainsProfessionnels`}
                    watchFields={[
                      `perteDeGainsProfessionnels.rows.${index}.revenuDeReferencePeriode`,
                      `perteDeGainsProfessionnels.rows.${index}.revenuPercuParPeriode`,
                      `perteDeGainsProfessionnels.rows.${index}.montantsDejaRevalorises`,
                      `perteDeGainsProfessionnels.rows.${index}.dateFin`,
                      'revalorisationCoefficientsType',
                    ]}
                    control={control}
                    compute={([
                      revenuDeReferencePeriode,
                      revenuPercuParPeriode,
                      montantsDejaRevalorises,
                      dateFin,
                      revalorisationCoefficientsType,
                    ]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getPerteDeGainsProfessionnels(
                        {
                          revenuDeReferencePeriode,
                          revenuPercuParPeriode,
                          montantsDejaRevalorises,
                          dateFin,
                          monetaryErosions,
                          dateLiquidation,
                          revalorisationCoefficientsType,
                        },
                      )
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        suffix: '€',
                        numberMask: { scale: 2, signed: true },
                      } as IMaskNumberProps,
                    }}
                    render={(value) => <>{fCurrency(value)}</>}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={handleMenuOpen(index)}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openMenuIndex === index}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleDelete(index)}>
                      {t('forms.table.deleteRow', { index: index + 1 })}
                    </MenuItem>
                    <MenuItem onClick={() => duplicateRow(index)}>
                      {t('forms.table.duplicateRow', { index: index + 1 })}
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={11}>
                <Stack
                  direction={'row'}
                  spacing={0}
                  alignItems="center"
                  justifyContent="right"
                >
                  <Typography>
                    {t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.revenuPercuTotal.title',
                    )}
                  </Typography>
                  <ComputedReadFieldForm
                    control={control}
                    watchFields={['perteDeGainsProfessionnels.rows']}
                    name={'perteDeGainsProfessionnels.revenusPercusTotal'}
                    compute={([rows]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getTotalPerteDeGainsProfessionnelsRevenusPercus(
                        { rows },
                      )
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        suffix: '€',
                        numberMask: { scale: 2 },
                      } as IMaskNumberProps,
                    }}
                    render={(value) => <p>&nbsp;: {fCurrency(value)}</p>}
                  />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={11}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems="center"
                  justifyContent="right"
                >
                  <Typography>
                    {formType === 'REVENUS'
                      ? t(
                          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.revenuReferenceTotal.title',
                        )
                      : t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.perteDeGainsProfessionnels.revenuReferenceTotal.title',
                        )}
                  </Typography>
                  <ComputedTextFieldForm
                    control={control}
                    watchFields={[
                      'perteDeGainsProfessionnels.rows',
                      ...((formType === 'REVENUS'
                        ? ['revenuActiviteAnnuelDeReference.total']
                        : ['revenuEspere.total']) as (
                        | 'revenuActiviteAnnuelDeReference.total'
                        | 'revenuEspere.total'
                      )[]),
                    ]}
                    name={'perteDeGainsProfessionnels.revenuReferenceTotal'}
                    compute={([rows, revenuReferenceOuEspereAnnuel]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getTotalRevenuReferenceOuEspere(
                        {
                          rows,
                          revenuReferenceOuEspereAnnuel,
                          dateAccident: victime.dateAccident,
                          dateConsolidation: procedure.dateConsolidation,
                        },
                      )
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        suffix: '€',
                        numberMask: { scale: 2 },
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={11}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems="center"
                  justifyContent="right"
                >
                  <Typography>
                    {t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.total.title',
                    )}
                  </Typography>
                  <ComputedTextFieldForm
                    control={control}
                    watchFields={[
                      'perteDeGainsProfessionnels.revenuReferenceTotal',
                      'perteDeGainsProfessionnels.revenusPercusTotal',
                    ]}
                    name={'perteDeGainsProfessionnels.total'}
                    compute={([revenuReferenceTotal, revenusPercusTotal]) =>
                      revenuReferenceTotal - revenusPercusTotal
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        suffix: '€',
                        numberMask: { scale: 2 },
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </Stack>
              </TableCell>
            </TableRow>
          </TableFooter>
        </NormalTable>
      </TableContainer>
      <Button sx={{ alignSelf: 'center' }} onClick={onClickAppend}>
        {t('forms.table.addRow')}
      </Button>
      {isError && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={'center'}
        >
          <Typography color="error" align={'center'} fontWeight={'bold'}>
            {t(
              'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.stepper.error.requiredStep',
            )}
          </Typography>
        </Stack>
      )}
    </>
  );
};
export default TablePerteDeGainsProfessionnels;
