import React, { useMemo, useState } from 'react';
import { useForm, FieldPath } from 'react-hook-form';
import * as yup from 'yup';
import {
  CapitauxDeces,
  PrejudiceFormPerteRevenusProche,
  PrejudiceFormPerteRevenusProcheEnfantRow,
  PrejudiceType,
  RevenuProche,
} from '../../../../types/prejudice.type';
import { VictimeIndirecte } from '../../../../types/victimeIndirecte.type';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  Stack,
  FormLabel,
  FormControl,
  Stepper,
  Step,
  Box,
  StepLabel,
  InputAdornment,
} from '@mui/material';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { PrejudiceContainer } from '../../PrejudiceContainer';
import { PrejudiceFormProps } from '../../PrejudiceFormProps';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { getCapitalisationBaremes } from 'src/helpers/prejudices/capitalisation';
import { useTranslation } from 'react-i18next';
import {
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
  validationSchemaPrejudiceFormCapitalisationOrRenteBase,
} from 'src/constants/prejudice/validation';
import i18next from 'i18next';
import { MAX_TIMESTAMP } from 'src/helpers/formatTime';
import { Chiffrage } from '../../prejudiceFormComponents/Chiffrage';
import { Procedure } from 'src/types/procedure.type';
import { SavePrejudiceButton } from '../../SavePrejudiceButton';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { PerteAnnuelleFoyer } from './Steps/PerteAnnuelleFoyer';
import { PrejudiceViagerFoyer } from './Steps/PrejudiceViagerFoyer';
import { PerteConjoint } from './Steps/PerteConjoints';
import { PerteEnfants } from './Steps/PerteEnfants';
import { Recapitulatif } from './Steps/Recapitulatif';
import { getFormErrorFields } from 'src/helpers/reactHookForm';
import { CalculPerteRevenusProche } from 'src/constants/calculs/calculPerteRevenusProche';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { DintilhacButton } from '../../prejudiceFormComponents/DintilhacButton';
import { BaremeChoice } from './Steps/BaremeChoice';

const validationSchemaRevenuProche = ({
  fieldName,
  dateDeces,
}: {
  fieldName:
    | 'victime.revenu'
    | 'conjoint.revenuAvantDeces'
    | 'conjoint.revenuApresDeces';
  dateAccident: Date | undefined;
  dateDeces: Date | undefined;
}): yup.ObjectSchema<RevenuProche> =>
  yup.object({
    montant: yup.number().optional().defined(),
    annee: yup
      .number()
      .when([], (_, schema) => {
        if (fieldName === 'conjoint.revenuApresDeces' && dateDeces) {
          return schema.min(
            dateDeces.getFullYear(),
            i18next.t('validation.prejudices.dates.minDateDeces'),
          );
        } else {
          return schema.max(
            dateDeces?.getFullYear() || new Date(MAX_TIMESTAMP).getFullYear(),
            i18next.t('validation.prejudices.dates.maxDateDeces'),
          );
        }
      })
      .nullable()
      .defined()
      .when('dejaRevalorise', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    dejaRevalorise: yup.boolean().optional().defined(),
    revaloriseMontant: yup.number().optional().defined(),
    numerosPieces: validationSchemaNumeroPieces,
  });

const validationSchemaCapitauxDeces = (
  procedure: Procedure,
): yup.ObjectSchema<CapitauxDeces> =>
  yup.object({
    tiersPayeurs: yup
      .array()
      .defined()
      .of(
        yup.object({
          tiersPayeur: yup.string().oneOf(procedure.tiersPayeurs).required(),
          montant: yup.number().required(),
        }),
      ),
    total: yup.number().min(0).required(),
  });

export const validationSchemaPertesRevenusProcheVictimeDecedee = ({
  dateAccident,
  dateDeces,
  procedure,
  victimesIndirectes,
}: {
  dateAccident: Date | undefined;
  dateDeces: Date | undefined;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
}): yup.ObjectSchema<PrejudiceFormPerteRevenusProche> => {
  return validationSchemaPrejudiceBase
    .concat(validationSchemaPrejudiceFormCapitalisationOrRenteBase)
    .shape({
      victime: yup.object({
        revenu: validationSchemaRevenuProche({
          fieldName: 'victime.revenu',
          dateAccident,
          dateDeces,
        }),
        coefficientCapitalisation: yup.number().min(0).max(100).optional(),
      }),
      conjoint: yup.object({
        revenuAvantDeces: validationSchemaRevenuProche({
          fieldName: 'conjoint.revenuAvantDeces',
          dateAccident,
          dateDeces,
        }),
        revenuApresDeces: validationSchemaRevenuProche({
          fieldName: 'conjoint.revenuApresDeces',
          dateAccident,
          dateDeces,
        }),
        partTotale: yup.number().min(0).max(100).required(),
        coefficientCapitalisation: yup.number().min(0).max(100).optional(),
        total: yup.number().required(),
        capitauxDeces: validationSchemaCapitauxDeces(procedure),
        totalApresCapitauxDeces: yup.number().required(),
      }),
      foyer: yup.object({
        revenuAnnuel: yup.number().required(),
        pourcentageConsomVictime: yup.number().min(0).max(100).required(),
        deductionPartConsomVictime: yup.number().required(),
        perteAnnuelle: yup.number().required(),
        prejudiceViagerEntreDecesEtLiquidation: yup.number().required(),
        prejudiceViagerApresLiquidation: yup.number().required(),
        prejudiceViager: yup.number().optional().defined(),
      }),
      baremeCapitalisation: yup.string().required(),

      enfants: yup.object({
        rows: yup
          .array()
          .defined()
          .of(
            yup.object({
              victimeIndirecteId: yup.string().required(),
              partIndividuelle: yup.number().min(0).max(100).required(),
              partPerteAnnuelle: yup.number().required(),
              dureeIndemnisation: yup.number().required(),
              prejudiceEntreDecesEtLiquidation: yup.number().required(),
              ageFinSoutienFinancier: yup.lazy((value) =>
                value === ''
                  ? (yup
                      .string()
                      .defined()
                      .transform(() => null) as unknown as yup.NumberSchema<
                      number,
                      yup.AnyObject,
                      undefined,
                      ''
                    >)
                  : yup.number().defined().nullable().min(0),
              ),
              coefficientCapitalisation: yup
                .number()
                .nullable()
                .optional()
                .min(0)
                .max(150)
                .when(
                  ['ageFinSoutienFinancier', 'victimeIndirecteId'],
                  (fields, schema) => {
                    const [ageFinSoutienFinancier, victimeIndirecteId] = fields;
                    const victimeIndirecte = victimesIndirectes.find(
                      (victime) => victime._id === victimeIndirecteId,
                    );
                    if (!victimeIndirecte || !procedure.dateLiquidation) {
                      return schema;
                    }
                    const isFinSoutienFinancierBeforeLiquidation =
                      CalculPerteRevenusProche.isFinSoutienFinancierBeforeLiquidation(
                        {
                          dateNaissance: victimeIndirecte.dateNaissance,
                          dateLiquidation: procedure.dateLiquidation,
                          ageFinSoutienFinancier:
                            ageFinSoutienFinancier ?? undefined,
                        },
                      );
                    if (!isFinSoutienFinancierBeforeLiquidation) {
                      return schema.required();
                    }
                    return schema;
                  },
                ),
              montantCapitalise: yup
                .number()
                .when(
                  ['ageFinSoutienFinancier', 'victimeIndirecteId'],
                  (fields, schema) => {
                    const [ageFinSoutienFinancier, victimeIndirecteId] = fields;
                    const victimeIndirecte = victimesIndirectes.find(
                      (victime) => victime._id === victimeIndirecteId,
                    );
                    if (!victimeIndirecte || !procedure.dateLiquidation) {
                      return schema;
                    }
                    const isFinSoutienFinancierBeforeLiquidation =
                      CalculPerteRevenusProche.isFinSoutienFinancierBeforeLiquidation(
                        {
                          dateNaissance: victimeIndirecte.dateNaissance,
                          dateLiquidation: procedure.dateLiquidation,
                          ageFinSoutienFinancier:
                            ageFinSoutienFinancier ?? undefined,
                        },
                      );
                    if (!isFinSoutienFinancierBeforeLiquidation) {
                      return schema.required();
                    }
                    return schema;
                  },
                ),
              total: yup.number().required(),
              totalApresCapitauxDeces: yup.number().required(),
              capitauxDeces: validationSchemaCapitauxDeces(procedure),
            }),
          ),
        partTotale: yup.number().min(0).max(100).required(),
        total: yup.number().required(),
        totalCapitauxDeces: yup.number().required(),
        totalApresCapitauxDeces: yup.number().required(),
      }),
      partResponsabilite: yup
        .number()
        .required()
        .min(0)
        .max(100)
        .transform((value) => value / 100),
      revalorisationCoefficientsType: yup
        .string()
        .oneOf(['smic', 'annuel'])
        .required(),
      formType: yup.string().oneOf(['UNE_PERIODE']).required(),
    });
};

const defaultRevenuProcheValue: RevenuProche = {
  montant: 0,
  annee: null,
  dejaRevalorise: false,
  revaloriseMontant: 0,
  numerosPieces: { rows: [] },
};
const getDefaultCapitauxDeces = (
  values: CapitauxDeces | undefined,
  procedure: Procedure,
) => ({
  tiersPayeurs: procedure.tiersPayeurs.map((tiersPayeur) => ({
    tiersPayeur,
    montant:
      (Array.isArray(values?.tiersPayeurs)
        ? values?.tiersPayeurs ?? []
        : []
      ).find((tiersPayeurValue) => tiersPayeurValue.tiersPayeur === tiersPayeur)
        ?.montant || 0,
  })),
  total: values?.total || 0,
});

export const perteRevenusProcheVictimeDecedeeDefaultValues = ({
  procedure,
  victimesIndirectes,
  values,
  prejudiceType,
}: {
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
  values: PrejudiceFormPerteRevenusProche | undefined;
  prejudiceType: PrejudiceType;
}): PrejudiceFormPerteRevenusProche => {
  const victimesIndirectesEnfants = victimesIndirectes.filter(
    (victime) => victime.lienVictime === 'enfant',
  );
  return {
    ...prejudiceBaseDefaultValues({
      prejudiceType,
      values,
    }),
    notes: values?.notes || '',
    victime: {
      revenu: values?.victime?.revenu || defaultRevenuProcheValue,
      coefficientCapitalisation: values?.victime?.coefficientCapitalisation,
    },
    conjoint: {
      revenuAvantDeces:
        values?.conjoint?.revenuAvantDeces || defaultRevenuProcheValue,
      revenuApresDeces:
        values?.conjoint?.revenuApresDeces || defaultRevenuProcheValue,
      partTotale: values?.conjoint?.partTotale || 0,
      coefficientCapitalisation: values?.conjoint?.coefficientCapitalisation,
      total: values?.conjoint?.total || 0,
      capitauxDeces: getDefaultCapitauxDeces(
        values?.conjoint?.capitauxDeces,
        procedure,
      ),
      totalApresCapitauxDeces: values?.conjoint?.totalApresCapitauxDeces || 0,
    },
    foyer: {
      revenuAnnuel: values?.foyer?.revenuAnnuel || 0,
      pourcentageConsomVictime: values?.foyer?.pourcentageConsomVictime ?? null,
      deductionPartConsomVictime:
        values?.foyer?.deductionPartConsomVictime || 0,
      perteAnnuelle: values?.foyer?.perteAnnuelle || 0,
      prejudiceViager: values?.foyer?.prejudiceViager || 0,
      prejudiceViagerEntreDecesEtLiquidation:
        values?.foyer?.prejudiceViagerEntreDecesEtLiquidation || 0,
      prejudiceViagerApresLiquidation:
        values?.foyer?.prejudiceViagerApresLiquidation || 0,
    },
    baremeCapitalisation: values?.baremeCapitalisation || '',
    enfants: {
      rows: victimesIndirectesEnfants.map(
        (victimeIndirecte): PrejudiceFormPerteRevenusProcheEnfantRow => {
          const existingValues = values?.enfants?.rows?.find(
            (prejudiceEnfant) =>
              prejudiceEnfant.victimeIndirecteId === victimeIndirecte._id,
          );
          return {
            victimeIndirecteId: victimeIndirecte._id,
            partPerteAnnuelle: 0,
            dureeIndemnisation: 0,
            prejudiceEntreDecesEtLiquidation: 0,
            ageFinSoutienFinancier: null,
            coefficientCapitalisation: undefined,
            total: 0,
            totalApresCapitauxDeces: 0,
            montantCapitalise: 0,
            ...existingValues,
            capitauxDeces: getDefaultCapitauxDeces(
              existingValues?.capitauxDeces,
              procedure,
            ),
          };
        },
      ),
      partTotale: values?.enfants?.partTotale || 0,
      total: values?.enfants?.total || 0,
      totalCapitauxDeces: values?.enfants?.totalCapitauxDeces || 0,
      totalApresCapitauxDeces: values?.enfants?.totalApresCapitauxDeces || 0,
    },
    partResponsabilite:
      (values?.partResponsabilite || procedure.partResponsabilite) * 100,
    revalorisationCoefficientsType:
      values?.revalorisationCoefficientsType || 'annuel',
    formType: 'UNE_PERIODE',
    isRentesOption: values?.isRentesOption || false,
    rentes: {
      montant: values?.rentes?.montant || 0,
    },
  };
};

const StepContainer: React.FC<{
  children: React.ReactNode;
  step: StepType;
  activeStep: StepType;
}> = ({ children, step, activeStep }) => (
  <Box
    hidden={step !== activeStep}
    visibility={step === activeStep ? 'visible' : 'hidden'}
  >
    {children}
  </Box>
);

interface Props extends PrejudiceFormProps<PrejudiceFormPerteRevenusProche> {
  victimesIndirectes: VictimeIndirecte[];
}

enum StepType {
  foyer = 'foyer',
  foyerViager = 'foyerViager',
  enfants = 'enfants',
  conjoint = 'conjoint',
  recapitulatif = 'recapitulatif',
}

const steps: Record<StepType, FieldPath<PrejudiceFormPerteRevenusProche>[]> = {
  foyer: [
    'conjoint.revenuApresDeces.annee',
    'conjoint.revenuApresDeces.montant',
    'conjoint.revenuAvantDeces.annee',
    'conjoint.revenuAvantDeces.montant',
    'victime.revenu.annee',
    'victime.revenu.montant',
    'foyer.revenuAnnuel',
    'foyer.pourcentageConsomVictime',
    'foyer.deductionPartConsomVictime',
    'foyer.perteAnnuelle',
  ],
  foyerViager: [
    'foyer.prejudiceViagerEntreDecesEtLiquidation',
    'victime.coefficientCapitalisation',
    'conjoint.coefficientCapitalisation',
    'foyer.prejudiceViagerApresLiquidation',
    'foyer.prejudiceViager',
    'baremeCapitalisation',
  ],
  enfants: [
    'enfants.rows',
    'enfants.partTotale',
    'enfants.total',
    'enfants.totalCapitauxDeces',
    'enfants.totalApresCapitauxDeces',
    'baremeCapitalisation',
  ],
  conjoint: [
    'conjoint.total',
    'conjoint.capitauxDeces',
    'conjoint.totalApresCapitauxDeces',
  ],
  recapitulatif: [],
};

export const FormPertesRevenusProcheVictimeDecedee: React.FC<Props> = ({
  values,
  victime,
  victimesIndirectes,
  procedure,
  monetaryErosions,
  baremes,
  dateLiquidation,
  allNumerosPieces,
  ...props
}) => {
  const { t } = useTranslation();
  const capitalisationBaremes = useMemo(
    () => getCapitalisationBaremes(baremes, victime.sexe),
    [baremes],
  );

  const useFormReturn = useForm<PrejudiceFormPerteRevenusProche>({
    mode: 'onTouched',
    defaultValues: perteRevenusProcheVictimeDecedeeDefaultValues({
      procedure,
      victimesIndirectes,
      values,
      prejudiceType: props.prejudiceType,
    }),
    resolver: yupResolver(
      validationSchemaPertesRevenusProcheVictimeDecedee({
        dateAccident: victime.dateAccident
          ? new Date(victime.dateAccident)
          : undefined,
        dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
        procedure,
        victimesIndirectes,
      }),
    ),
  });

  const { control, formState, trigger } = useFormReturn;

  const [activeStep, setActiveStep] = useState<StepType>(StepType.foyer);

  const errorsByStep = useMemo(() => {
    return Object.entries(steps).reduce(
      (accumulator, [stepName, fields]) => {
        accumulator[stepName as StepType] = getFormErrorFields(
          formState.errors,
          fields,
        );
        return accumulator;
      },
      {} as Record<StepType, string[]>,
    );
  }, [formState.errors]);

  const changeStepAndValidate = async (step: StepType) => {
    const currentStep = steps[activeStep];
    if (await trigger(currentStep)) {
      setActiveStep(step);
    }
  };
  const stepTitles: Record<StepType, string> = useMemo(
    () => ({
      foyer: t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.steps.foyer.title',
      ),
      foyerViager: t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.steps.foyerViager.title',
      ),
      enfants: t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.steps.enfants.title',
      ),
      conjoint: t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.steps.conjoint.title',
        {
          deces: !!victime.dateDeces,
        },
      ),
      recapitulatif: t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.steps.recapitulatif.title',
      ),
    }),
    [victime.dateDeces],
  );

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormPerteRevenusProche>
      {...props}
      procedure={procedure}
      victime={victime}
      baremes={baremes}
      {...useFormReturn}
      monetaryErosions={monetaryErosions}
      victimesIndirectes={victimesIndirectes}
      renderPrejudice={() => (
        <Stack direction="column" spacing={3} alignItems="start" height="748px">
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction.victimeDecedee`,
            )}
          />
          <ComputedTextFieldForm
            control={control}
            name="partResponsabilite"
            label={t('partResponsabilite.label')}
            watchFields={[]}
            compute={() => procedure.partResponsabilite * 100}
            InputLabelProps={{
              shrink: true,
              sx: { color: 'info.light' },
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2, min: 0, max: 100 },
                suffix: '%',
              } as IMaskNumberProps,
              sx: {
                color: 'info.light',
                borderColor: 'info.light',
                ' .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'info.light',
                },
              },
            }}
            sx={{ minWidth: '268px' }}
            editedFieldsName="editedFields"
          />
          <CalculsBox padding={2}>
            <FormControl>
              <FormLabel
                sx={{
                  textAlign: 'center',
                }}
              >
                {t('prejudice.revalorisation.coefficientsType.choice.label')}
              </FormLabel>
              <RadioFormField
                control={control}
                name="revalorisationCoefficientsType"
                options={[
                  {
                    label: t(
                      'prejudice.revalorisation.coefficientsType.choice.options.annuel',
                    ),
                    value: 'annuel',
                  },
                  {
                    label: t(
                      'prejudice.revalorisation.coefficientsType.choice.options.smic',
                    ),
                    value: 'smic',
                  },
                ]}
                row
              />
            </FormControl>
          </CalculsBox>
          <BaremeChoice
            control={control}
            capitalisationBaremes={capitalisationBaremes}
          />
          <Stepper
            nonLinear
            activeStep={Object.keys(StepType).indexOf(activeStep)}
            sx={{ alignSelf: 'center', width: '100%' }}
          >
            {Object.values(StepType).map((step) => {
              const isErrorDisplayed =
                errorsByStep[step].length > 0 && step !== activeStep;
              const errorStyle = {
                color: 'error.main',
              };
              return (
                <Step key={step}>
                  <StepLabel
                    onClick={() => changeStepAndValidate(step)}
                    sx={{
                      cursor: 'pointer',
                      textAlign: 'center',
                      '& .MuiStepLabel-label': isErrorDisplayed
                        ? errorStyle
                        : undefined,
                      '& .MuiSvgIcon-root': isErrorDisplayed
                        ? errorStyle
                        : undefined,
                    }}
                  >
                    {stepTitles[step]}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <StepContainer step={StepType.foyer} activeStep={activeStep}>
            <PerteAnnuelleFoyer
              control={control}
              allNumerosPieces={allNumerosPieces}
              procedure={procedure}
              monetaryErosions={monetaryErosions}
            />
          </StepContainer>
          <StepContainer step={StepType.foyerViager} activeStep={activeStep}>
            <PrejudiceViagerFoyer
              control={control}
              procedure={procedure}
              victime={victime}
              baremes={baremes}
              victimesIndirectes={victimesIndirectes}
              dateLiquidation={dateLiquidation}
            />
          </StepContainer>
          <StepContainer step={StepType.conjoint} activeStep={activeStep}>
            <PerteConjoint control={control} procedure={procedure} />
          </StepContainer>
          <StepContainer step={StepType.enfants} activeStep={activeStep}>
            <PerteEnfants
              control={control}
              procedure={procedure}
              victime={victime}
              victimesIndirectes={victimesIndirectes}
              baremes={baremes}
              capitalisationBaremes={capitalisationBaremes}
              dateLiquidation={dateLiquidation}
            />
          </StepContainer>
          <StepContainer step={StepType.recapitulatif} activeStep={activeStep}>
            <Recapitulatif
              control={control}
              procedure={procedure}
              victimesIndirectes={victimesIndirectes}
            />
          </StepContainer>
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Stack>
      )}
    />
  );
};
